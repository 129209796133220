import * as React from 'react'
import {Link} from 'gatsby'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export const PostCard = ({post}) => (
    <Card>
        <CardContent>
            <div>
                <Link
                    to={post.fields.slug}
                    style={{color: 'black', fontWeight: 'bold'}}
                >
                    {post.frontmatter.title}
                </Link>
                <span
                    style={{
                        float: 'right',
                        color: 'grey',
                    }}
                >
            {post.frontmatter.date}
          </span>
            </div>
            {post.excerpt}

        </CardContent>
    </Card>
)
